/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../../theme"
import Markdown from "react-markdown"
import CloseIcon from "../../assets/close-icon"
import { TransformLinkUri } from "../link"

const InfoPopup = ({ text, expand }) => {
  const { rawColors: colors } = useThemeUI().theme

  return (
    <div
      className="info_div"
      sx={{
        fontWeight: "bold",
        fontSize: "10px",
        lineHeight: 1,
        color: "#004787",
        textAlign: ab({ _: "", md: "right" }),
        cursor: "pointer",
        display: "inline",
        position: ab({ _: "", md: "relative" }),
        top: "-5px",
        "&:hover ": {
          "& strong ": {
            display: "inline-block",
          },
        },
      }}
    >
      <span
        className="info_icon"
        sx={{
          fontFamily: "none",
          background: "#fff",
          borderRadius: "30px",
          px: "6px",
          py: "1px",
          fontWeight: "400",
        }}
      >
        i
      </span>
      <div
        className="text"
        sx={{
          display: expand ? "block" : "none",
          position: "absolute",
          width: ab({ _: "100vw ", md: "250px" }),
          top: ab({ _: "64px", md: "25px" }),
          right: ab({ _: "0", md: "-35px" }),
          bg: "rgb(229, 237, 242)",
          color: "text",
          fontWeight: "normal",
          p: 3,
          lineHeight: 1.4,
          borderBottom: "4px solid",
          borderColor: colors.primary,
          fontSize: "16px !important",
          textAlign: "left",
          zIndex: 10,
          "& span": {
            width: 0,
            height: 2,
            position: "absolute",
            right: ab({ _: "14.5%", md: "32px" }),
            top: "-15px",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderBottom: " 15px solid #E5EDF2",
          },
          "& a": {
            color: colors.primary,
            textDecoration: "underline",
            "&:hover": {
              textDecoration: "none",
            },
          },
        }}
      >
        <strong
          sx={{
            width: 0,
            height: "10px",
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            borderBottom: " 10px solid #E5EDF2",
            position: "absolute",
            top: ab({ _: "", md: "-10px" }),
            left: ab({ _: "", md: "auto" }),
            right: ab({ _: "", md: "32px" }),
            ml: ab({ _: "-15px", md: "" }),
            mt: ab({ _: "0px", md: "auto" }),
            zIndex: 115,
          }}
        ></strong>
        <div
          role="button"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "23px",
            height: "23px",
            padding: "2px",
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </div>
        <Markdown
          source={text}
          linkTarget={(url) => {
            const fileExtension = url.substr(url.lastIndexOf(".") + 1)

            if (fileExtension === "pdf") {
              return "_blank"
            }

            return undefined
          }}
          transformLinkUri={(uri) => {
            return TransformLinkUri(uri)
          }}
        />
      </div>
    </div>
  )
}

InfoPopup.defaultProps = {
  expand: false,
}

InfoPopup.propTypes = {
  text: PropTypes.string.isRequired,
  expand: PropTypes.bool,
}

export default InfoPopup
