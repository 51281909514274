import React from "react"
import PropTypes from "prop-types"
import ThemeContext from "../context/theme-context"

import SearchModal from "./search-modal"

const SearchModalConsumer = ({
  buttonText,
  optionText,
  placeholder,
  refineLanguage,
  refineMode,
  appId,
  searchKey,
  indexName,
}) => (
  <ThemeContext.Consumer>
    {(theme) => (
      <SearchModal
        active={theme.searchVisible}
        buttonText={buttonText}
        optionText={optionText}
        placeholder={placeholder}
        refineLanguage={refineLanguage}
        refineMode={refineMode}
        toggleSearchDialog={theme.toggleSearchVisibility}
        appId={appId}
        searchKey={searchKey}
        indexName={indexName}
      />
    )}
  </ThemeContext.Consumer>
)

SearchModalConsumer.propTypes = {
  buttonText: PropTypes.string.isRequired,
  optionText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  refineLanguage: PropTypes.array.isRequired,
  refineMode: PropTypes.array.isRequired,
  appId: PropTypes.string.isRequired,
  searchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
}

export default SearchModalConsumer
