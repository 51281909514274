/** @jsx jsx */
import { get, jsx } from "theme-ui"
import PropTypes from "prop-types"
import SitesContext from "../../context/site-context"
import { useEffect, useContext, useState } from "react"
import Locations from "../locations"

const SitesMap = ({
  currentLanguage,
  centerLng,
  ceneterLat,
  zoomLevel,
  apiKey,
  thirdPartyInfo,
  mapSearchRadius,
}) => {
  const { getSiteListing, getSerach, setPlzFilters } = useContext(SitesContext)
  let searchPlz = getSerach().ortPlz || false
  let searchCountry = getSerach().countries || []
  let searchindustry = getSerach().industries || []
  let searchCompanys = getSerach().companys || []
  const [closestPin, setClosestPin] = useState({})
  const [mapCenter, setMapCenter] = useState({
    lat: ceneterLat,
    lng: centerLng,
  })
  const [zoom, setZoom] = useState(zoomLevel)

  let plzFiltersValues = []
  let locations = []
  const listing = getSiteListing()

  const findPins = (targetLat, targetLng, markers, radius) => {
    const R = 6371 // Earth's radius in kilometers
    const pinsWithinRadius = []

    let nearestPin = null
    let nearestDistance = Infinity

    for (const marker of markers) {
      const dLat = (marker.latitude - targetLat) * (Math.PI / 180)
      const dLng = (marker.longitude - targetLng) * (Math.PI / 180)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(targetLat * (Math.PI / 180)) *
          Math.cos(marker.latitude * (Math.PI / 180)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const distance = R * c

      if (distance <= radius) {
        pinsWithinRadius.push(marker)
      }

      if (distance < nearestDistance) {
        nearestDistance = distance
        nearestPin = marker
      }
    }
    if (pinsWithinRadius.length > 0) {
      return pinsWithinRadius
    } else {
      return [nearestPin]
    }
  }

  Object.entries(listing).forEach(([groupName, group]) =>
    group.forEach((site, i) => {
      let siteLink = site.linkLocation ? site.linkLocation.id : site.slug
      let data
      if (typeof site.latitude === "undefined") {
        return
      }
      data = {
        email: "",
        fax: "",
        heading: site.name,
        itsHeadline: "",
        latitude: site.latitude,
        longitude: site.longitude,
        otherInfo: "",
        subHeading: "",
        address: site.address,
        locationLink: siteLink,
      }

      locations.push(data)
    })
  )

  useEffect(() => {
    asyncFn()
  }, [searchPlz, searchCountry])

  const asyncFn = async () => {
    let searchLocation = searchPlz
      ? searchPlz
      : searchCountry.length
      ? searchCountry
      : false
    let zoomLocation = searchPlz ? 7 : 5

    if (searchLocation) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            searchLocation
          )}&key=${apiKey}`
        )
        if (response.ok) {
          const data = await response.json()
          if (data.results.length > 0) {
            const location = data.results[0].geometry.location
            if (searchPlz) {
              const pins = findPins(
                location.lat,
                location.lng,
                locations,
                mapSearchRadius
              )
              if (pins) {
                Object.entries(listing).forEach(([groupName, group]) =>
                  group.forEach((site, i) => {
                    pins.forEach((pin) => {
                      if (pin.latitude === site.latitude) {
                        if (!plzFiltersValues[groupName]) {
                          plzFiltersValues[groupName] = []
                        }
                        plzFiltersValues[groupName].push(site)
                      }
                    })
                  })
                )
                setPlzFilters(plzFiltersValues)
                setClosestPin({
                  lat: Number(pins[0].latitude),
                  lng: Number(pins[0].longitude),
                })
              }
            } else {
              setMapCenter(() => ({ lat: location.lat, lng: location.lng }))
              setZoom(zoomLocation)
            }
          }
        } else {
          console.error("Error searching for location")
        }
      } catch (error) {
        console.error("Error searching for location:", error)
      }
    }
  }

  useEffect(() => {
    if (
      searchPlz === "" ||
      (!searchCompanys.length &&
        !searchindustry.length &&
        !searchCountry.length)
    ) {
      setZoom(() => zoomLevel)
      setMapCenter(() => ({
        lat: Number(ceneterLat),
        lng: Number(centerLng),
      }))
    }
  }, [searchPlz, searchCompanys, searchindustry, searchCountry])

  useEffect(() => {
    if (closestPin) {
      setZoom(() => 7)
      setMapCenter(closestPin)
    }
  }, [closestPin])

  return (
    <div
      sx={{
        "> div": {
          padding: 0,
          mb: 0,
        },
      }}
    >
      <Locations
        hideLocations={true}
        location={locations}
        currentLanguage={currentLanguage}
        zoomLevel={zoom}
        apiKey={apiKey}
        thirdPartyInfo={thirdPartyInfo}
        mapCenter={mapCenter}
        defaultZoomLevel={zoomLevel}
        ceneterLat={ceneterLat}
        centerLng={centerLng}
      ></Locations>
    </div>
  )
}

export default SitesMap
