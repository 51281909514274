/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import PropTypes from "prop-types"

import Search from "./search"

import { CloseIcon } from "../assets/icons"
import { ab, navigationMobileHeight } from "../theme"

const SearchModal = ({
  active,
  buttonText,
  optionText,
  placeholder,
  refineLanguage,
  refineMode,
  toggleSearchDialog,
  appId,
  searchKey,
  indexName,
}) => {
  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [active])

  if (!active) {
    return <div></div>
  }

  return (
    <div
      sx={{
        variant: "searchModal",
        position: "fixed",
        top: ab({ _: 60, sm: 0 }),
        bottom: ab({ _: 80, sm: 0 }),
        width: "100%",
        animationName: active ? "Show" : "Hide",
        animationDuration: "400ms",
        animationFillMode: "forwards",
        "@keyframes Show": ab({
          _: {
            "0%": {
              zIndex: -1,
            },
            "1%": {
              zIndex: 100,
            },
            "100%": {
              zIndex: 100,
            },
          },
          md: {
            "0%": {
              zIndex: -1,
            },
            "1%": {
              zIndex: 105,
            },
            "100%": {
              zIndex: 105,
            },
          },
        }),
        "@keyframes Hide": {
          "0%": {
            zIndex: 105,
          },
          "99%": {
            zIndex: 105,
          },
          "100%": {
            zIndex: -1,
          },
        },
      }}
      data-cy-identifier="search-modal"
    >
      <div
        role="button"
        tabIndex="0"
        onKeyPress={() => toggleSearchDialog(false)}
        onClick={() => toggleSearchDialog(false)}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          animationName: active ? "FadeIn" : "FadeOut",
          animationDuration: "400ms",
          animationFillMode: "forwards",
          "@keyframes FadeIn": {
            "0%": {
              opacity: 0,
              width: "100%",
            },
            "30%": {
              opacity: 0.5,
            },
            "100%": {
              opacity: 1,
              width: "100%",
            },
          },
          "@keyframes FadeOut": {
            "0%": {
              opacity: 1,
              width: "99%",
            },

            "99%": {
              width: "99%",
            },
            "100%": {
              opacity: 0,
              width: "0%",
            },
          },
        }}
      ></div>
      <div
        style={{
          display: "flex",
          position: "relative",
        }}
        sx={{
          p: ab({ _: 2, sm: 4 }),
          zIndex: "107",
          flexDirection: ab({ _: "column-reverse", sm: "row" }),
          width: ab({ _: "100%", md: "80%" }),
          float: "right",
          backgroundColor: "#fff",
          overflow: "auto",
          height: ab({
            _: `calc(100vh - ${navigationMobileHeight})`,
            md: "100vh",
          }),
          animationName: active ? "SlideIn" : "SlideOut",
          animationDuration: "400ms",
          animationFillMode: "forwards",
          opacity: "1 !important",
          "@keyframes SlideIn": {
            "0%": {
              mr: "-100%",
            },
            "100%": {
              mr: "0",
            },
          },
          "@keyframes SlideOut": {
            "0%": {
              mr: "0%",
              visibility: "visible",
            },
            "99%": {
              visibility: "visible",
            },
            "100%": {
              mr: "-100%",
              opacity: 0,
              visibility: "hidden",
            },
          },
        }}
      >
        <div
          sx={{
            flex: 1,
            flexGrow: 10,
            px: 2,
            mt: ab({ md: "15px" }),
            ml: ab({ _: 3, md: "25px" }),
            mr: ab({ _: 3, md: "auto" }),
            maxWidth: "100%",
            overflowY: "scroll",
            pr: ab({ _: 3, md: 4 }),
            paddingBottom: ab({ _: navigationMobileHeight, md: "0" }),
          }}
        >
          <Search
            active={active}
            indexName={indexName}
            refineLanguage={refineLanguage}
            refineMode={refineMode}
            buttonText={buttonText}
            placeholder={placeholder}
            closeAction={toggleSearchDialog}
            appId={appId}
            searchKey={searchKey}
            optionText={optionText}
          ></Search>
        </div>
        <div
          sx={{
            flex: ab({ _: 1, md: 3 }),
          }}
        >
          <div
            sx={{
              width: 32,
              height: 32,
              maxWidth: 34,
              mr: "7px",
              float: "right",
              cursor: "pointer",
            }}
            role="button"
            tabIndex="0"
            onKeyPress={() => toggleSearchDialog(false)}
            onClick={() => toggleSearchDialog(false)}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
SearchModal.propTypes = {
  active: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  optionText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  refineLanguage: PropTypes.array.isRequired,
  refineMode: PropTypes.array.isRequired,
  toggleSearchDialog: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  searchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
}

SearchModal.defaultProps = {
  active: false,
  toggleSearchDialog: PropTypes.func.isRequired,
}

export default SearchModal
