/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Markdown from "react-markdown"

import { TransformLinkUri } from "./link"
import Icon from "../assets/icon"
import Link from "./link"

const Marker = ({ heading, address, currentLanguage, locationLink }) => (
  <Link
    language={currentLanguage}
    to={locationLink}
    sx={{
      color: "text",
      textDecoration: "none",
      textOverflow: "ellipsis ",
    }}
  >
    <div
      sx={{
        cursor: "pointer",
        color: "#fff",
        mt: "-24px",
        ml: "-12px",
        "&:hover div": {
          display: "block",
        },
      }}
    >
      <Icon
        style={{
          fill: `primary`,
          border: "2px #fff solid",
        }}
        icon="pin"
      />

      <div
        sx={{
          bg: `primary`,
          width: 200,
          p: 2,
          position: "relative",
          zIndex: 10,
          display: "none",
          left: "20px",
          top: -40,
          boxShadow: "2x 2px 2px #000",
        }}
      >
        <h4 sx={{ m: 0 }}>{heading}</h4>
        <hr
          sx={{
            border: 0,
            height: 0,
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
          }}
        />
        <div
          sx={{
            "& a": {
              color: "#fff !important",
              textDecoration: "none !important",
            },
          }}
        >
          <Markdown
            source={address}
            TransformLinkUri={(uri) => {
              return TransformLinkUri(uri, "", currentLanguage)
            }}
          />
        </div>
      </div>
    </div>
  </Link>
)

Marker.propTypes = {
  heading: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
}

export default Marker
