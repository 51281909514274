/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useState } from "react"
import { ab } from "../theme"
import { TransformLinkUri } from "./link"

import Markdown from "react-markdown"
import Arrow from "../assets/left-icon"

const Location = ({
  heading,
  itsHeadline,
  subHeading,
  children,
  address,
  tel,
  fax,
  email,
  otherInfo,
  latitude,
  longitude,
  currentLanguage,
}) => {
  const [toggle, setToggle] = useState(false)

  itsHeadline =
    itsHeadline ||
    (!children &&
      !address &&
      !tel &&
      !fax &&
      !email &&
      !otherInfo &&
      !latitude &&
      !longitude)
  let isEmpty = itsHeadline && !heading && !subHeading

  const location = (
    <div>
      <div
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "flex-start",
          outline: "none",
        }}
        role="button"
        tabIndex="0"
        onKeyPress={() => setToggle(!toggle)}
        onClick={() => setToggle(!toggle)}
      >
        <span
          sx={{
            display: ab({ _: "block", sm: "none" }),
            maxWidth: 22,
            transform: toggle ? "rotate(-90deg)" : "rotate(-180deg)",
            width: 32,
            transition: "all ease-in-out .1s",
            mt: "-2px",
          }}
        >
          <Arrow />
        </span>
        <h2
          sx={{
            variant: "sytles.h2",
            my: ab({ _: 1, sm: 3 }),
            mx: { _: 2, sm: 0 },
          }}
        >
          {heading}
        </h2>
      </div>
      <div
        sx={{
          display: ab({ _: !toggle ? "none" : "block", sm: "block" }),
          pl: ab({ _: 4, md: 0 }),
        }}
      >
        <address
          sx={{
            fontStyle: "normal",
            my: 3,
          }}
        >
          {children}
          <Markdown
            source={address}
            TransformLinkUri={(uri) => {
              return TransformLinkUri(uri, "", currentLanguage)
            }}
          />
        </address>
        <div
          sx={{
            "& a": {
              display: "block",
              textDecoration: "none",
            },
          }}
        >
          {tel} <br />
          {fax}
          <div>
            <a href={`mailto:${email}`}> {email}</a>
            <Markdown
              sx={{
                "& p": {
                  m: 0,
                  p: 0,
                },
              }}
              TransformLinkUri={(uri) => {
                return TransformLinkUri(uri, "", currentLanguage)
              }}
              renderers={{
                link: (props) => (
                  <a
                    href={props.href}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    {props.children}
                  </a>
                ),
              }}
              source={otherInfo}
            />
          </div>
        </div>
      </div>
    </div>
  )
  const H2 = (
    <h2
      sx={{
        variant: "sytles.h2",
        fontSize: ab({ md: "38px !important" }),
        pt: 4,
        mb: 1,
      }}
    >
      {heading}
    </h2>
  )
  const H3 = (
    <h3
      sx={{
        variant: "sytles.h2",
        fontSize: ab({ _: "22px !important", md: "28px !important" }),
        pt: 1,
        my: 1,
        fontWeight: "400",
        lineHeight: "30px",
      }}
    >
      {subHeading}
    </h3>
  )

  let showContent = () => {
    switch (itsHeadline) {
      case "h2":
        return H2
      case "h3":
        return H3
      default:
        return location
    }
  }
  return (
    <div
      sx={{
        width:
          itsHeadline === "h2" || itsHeadline === "h3"
            ? "100%"
            : ab({ _: "100%", md: "33%" }),
        height: isEmpty ? "0" : "auto",
        marginTop: ab({ _: 4, sm: 2 }),
        p: 2,
        "& + div": {
          marginTop: ab({ _: 0, sm: 2 }),
        },
        "& p": {
          m: 0,
          p: 0,
        },
      }}
    >
      {showContent()}
    </div>
  )
}
Location.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  itsHeadline: PropTypes.string,
  children: PropTypes.node,
  address: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
  fax: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  otherInfo: PropTypes.string,
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired,
}

export default Location
