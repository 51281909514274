/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { ab } from "../theme"

const Row = ({ children, rows, heading, gap, noPadding, anchorName }) => {
  return (
    <div id={anchorName ? anchorName : ""}>
      <h2
        sx={{
          display: heading ? "block" : "none",
          pl: 4,
          mb: 3,
        }}
      >
        {heading}
      </h2>
      <div>
        <div
          sx={{
            variant: noPadding ? "" : "spacingContainer",
            display: "grid",
            gap: gap ? gap + "px " : 4,
            gridTemplateColumns: ab({
              _: "1fr",
              md: rows
                ? `repeat(${rows} ,1fr)`
                : "repeat( auto-fit, minmax(100px, 1fr) )",
            }),
            ".page-teaser ": {
              mt: 0,
              mb: 0,
            },
            ".page-teaser > div": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pb: "0px",
              maxHeight: ab({ _: "auto", md: "140px", lg: " 100px" }),
            },
            ".page-teaser h2": {
              fontSize: "20px",
            },
            ".page-teaser > div > div": {
              position: "relative",
              pt: 2,
            },
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
Row.prototype = {
  childern: PropTypes.node,
}

export default Row
