/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import Image from "./image"

import { ArrowIcon } from "../assets/icons"

const SmallBlogTeaser = ({
  eventDate,
  eventImage,
  eventImageAlt,
  eventLink,
  linkTarget,
  mode,
  children,
  currentLanguage,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  return (
    <div
      className="smallblogteaser"
      sx={{
        variant: "innerSpacingContainer",
        flex: 0.3333,
      }}
    >
      <Link
        to={eventLink}
        language={currentLanguage}
        target={linkTarget}
        sx={{
          color: "inherit",
          textDecoration: "none",
        }}
      >
        <div
          sx={{
            position: "relative",
            bg: "background",
          }}
        >
          <span
            className="blog-date"
            data-mode={mode}
            sx={{
              variant: "smallblogteaser.date",
              bg: modeKey
                ? colors.modes[modeKey].primary
                : colors.modes["group"].primary,
              color: "background",
            }}
          >
            {eventDate}
          </span>
          <Image
            image={eventImage}
            alt={eventImageAlt}
            sizes={["100vw", "100vw", "34vw", "34vw"]}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div
          sx={{
            bg: "background",
            color: "text",
            p: 3,
            fontSize: 1,
          }}
        >
          <div
            sx={{
              p: 2,
              display: "flex",
            }}
          >
            <div
              sx={{
                flex: 8,
              }}
            >
              {children}
            </div>

            <div
              sx={{
                flex: 1,
                alignSelf: "flex-end",
              }}
            >
              <div
                sx={{
                  display: "block",
                  maxWidth: 32,
                  transform: "rotate(180deg)",
                }}
              >
                <ArrowIcon />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

SmallBlogTeaser.propTypes = {
  eventDate: PropTypes.string.isRequired,
  eventImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  eventImageAlt: PropTypes.string,
  children: PropTypes.node.isRequired,
  eventLink: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
}

SmallBlogTeaser.defaultProps = {
  mode: "",
}

export default SmallBlogTeaser
