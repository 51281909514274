/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import { ab } from "../theme"
import { useRef, useState, useEffect } from "react"
import Image from "./image"

function BannerNews({
  children,
  image,
  imageAlt,
  subHeading,
  heading,
  mode,
  currentLanguage,
  disableHyphens,
  newsHeading,
  moreNewsBtnText,
  moreNewsBtnLink,
  maxEntries,
  video,
}) {
  let hyphensStyling = {}
  if (disableHyphens) {
    hyphensStyling = {
      hyphens: "none",
      wordWrap: "normal",
    }
  }

  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme
  const [slideIndex, setSlideIndex] = useState(0)
  const [newsBoxHeight, setNewsBoxHeight] = useState(0)
  const newsElement = useRef(null)
  const showVideo = video && video.filename ? true : false

  const modeKey = mode ? mode : globalMode

  useEffect(() => {
    let x0 = null
    let locked = false
    let news = newsElement.current.querySelector(".news-box")
    let i = slideIndex
    const unify = (e) => {
      return e.changedTouches ? e.changedTouches[0] : e
    }

    const lock = (e) => {
      x0 = unify(e).clientX
      locked = true
    }

    const move = (e) => {
      if (locked) {
        let dx = unify(e).clientX - x0,
          s = Math.sign(dx)

        if ((i > 0 || s < 0) && (i < maxEntries - 1 || s > 0)) {
          i -= s
        }
        x0 = null
        locked = false
      }
      news.style.setProperty(
        "transform",
        `translateX(${(i / maxEntries) * -100}%)`
      )
      setSlideIndex(i)
    }

    function drag(e) {
      e.preventDefault()
      let dx = unify(e).clientX - x0,
        f = e.target.parentElement.clientWidth
      news.style.setProperty("transform", `translateX(calc(${-i * f + dx}px))`)
    }

    news.addEventListener("touchstart", lock, false)
    news.addEventListener("touchmove", drag, false)
    news.addEventListener("touchend", move, false)
  }, [])

  useEffect(() => {
    addActiveClass(slideIndex)
  }, [slideIndex])

  useEffect(() => {
    let news = newsElement.current
      .querySelectorAll(".banner-news-teaser")
      [slideIndex].querySelector("div")
    setNewsBoxHeight(news.clientHeight)
  }, [slideIndex])

  const addActiveClass = (idx) => {
    const dotDivs = newsElement.current.getElementsByClassName(
      "slide-" + idx
    )[0]
    let news = newsElement.current.querySelector(".news-box")
    news.style.setProperty(
      "transform",
      `translateX(${(idx / maxEntries) * -100}%)`
    )

    dotDivs.parentElement
      .querySelectorAll(".active")
      .forEach((e) => e.classList.remove("active"))
    dotDivs.classList.add("active")
  }

  const dots = () =>
    children &&
    children.map((child, index) => (
      <li
        className={"slide-" + index + " " + (index === 0 ? "active" : "")}
        key={index}
        role="button"
        tabIndex="0"
        onKeyPress={(e) => {
          addActiveClass(index)
          setSlideIndex(index)
        }}
        onClick={(e) => {
          addActiveClass(index)
          setSlideIndex(index)
        }}
        sx={{
          bg: "#5a5d5f59",
          p: "5px",
          m: "5px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
      />
    ))
  const BannerBg = showVideo ? (
    <video
      src={video.filename}
      autoPlay="autoplay"
      loop
      muted
      playsInline="playsinline"
      style={{
        width: "100%",
      }}
    />
  ) : (
    <Image
      image={image}
      alt={imageAlt ? imageAlt : heading}
      style={{
        width: "100%",
        height: ab({ _: "54vh", md: "calc(100vh - 50px)" }),
      }}
    />
  )

  return (
    <div
      sx={{
        position: "relative",
        height: ab({ _: "auto", md: "100vh" }),
        maxHeight: ab({ _: "auto", md: "100vh" }),
        overflow: "hidden",
        ...hyphensStyling,
      }}
    >
      <div
        sx={{
          color: "#fff",
          position: "relative",
          overflow: "hidden",
          height: ab({
            _: "auto",
            md: showVideo ? "calc(100vh - 40px)" : "auto",
          }),
        }}
      >
        {BannerBg}
      </div>
      <div
        className="whitFade"
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: ab({
            _: showVideo ? "76vw" : "56.3vh",
            md: "76vw",
            lg: "100vh",
          }),
          background: ab({
            _: "linear-gradient(180deg, rgba(255,255,255,0) 59%, rgba(255,255,255,0.7833508403361344) 75%, rgba(255,255,255,1) 100%)",
            md: "linear-gradient(90deg, rgba(255,255,255,0) 59%, rgba(255,255,255,0.7833508403361344) 75%, rgba(255,255,255,1) 100%)",
          }),
        }}
      ></div>
      <div
        className="news"
        sx={{
          color: colors.modes["group"].primary,
          borderLeft: ab({ _: "", md: "solid 1px " }),
          position: ab({ _: "relative", md: "absolute" }),
          top: ab({ _: "", md: "15%" }),
          width: ab({ _: "100%", md: "25%" }),
          right: ab({ _: 0, md: "50px" }),
          pl: ab({ _: 3, md: "50px" }),
          pr: ab({ _: 3, md: 0 }),
          zIndex: 5,
          mt: ab({ _: showVideo ? 0 : "-15vh", md: "0" }),
          mb: ab({ _: "48px", md: "0" }),
        }}
      >
        <h2
          sx={{
            mt: 0,
            mb: ab({ _: 2, md: 4 }),
            color: modeKey
              ? colors.modes[modeKey].primary
              : colors.modes["group"].primary,
            fontWeight: "bold !important",
            fontSize: ab({ _: "16px !important", md: "24px !important" }),
          }}
        >
          {newsHeading}
        </h2>
        <div
          ref={newsElement}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <div
            className="news-box"
            sx={{
              "& .banner-news-teaser": {
                width: ab({ _: 100 / maxEntries + "%", md: "100%" }),
                height: ab({ _: newsBoxHeight + "px", md: "100%" }),
                maxWidth: "100%",
              },
              display: ab({ _: "flex", md: "block" }),
              width: ab({ _: maxEntries * 100 + "%", md: "100%" }),
              overflowX: "hidden",
              transform: ab({ _: 0, md: 0 }),
              transition: "transform .3s ease-out",
            }}
          >
            {children}
          </div>
          <div
            sx={{
              textAlign: ab({ _: "center", md: "left" }),
              pb: ab({ _: "10px", md: "0" }),
            }}
          >
            <Link
              language={currentLanguage}
              to={moreNewsBtnLink}
              sx={{
                p: ab({ _: "10px 15px", md: "0" }),
                textDecoration: "none",
                fontSize: ab({ _: "12px", md: "16px" }),
                fontWeight: ab({ _: "600", md: "600" }),
                color: ab({ _: "#fff", md: "text" }),
                bg: ab({ _: colors.modes["group"].primary, md: "transparent" }),
                borderRadius: ab({ _: "50px", md: "" }),
              }}
            >
              {moreNewsBtnText}
            </Link>
          </div>

          <ul
            id="dots"
            sx={{
              m: 0,
              p: 0,
              bottom: -40,
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              display: ab({ _: "block", md: "none" }),

              "& > li": {
                color: colors.modes["group"].primary,
                display: "inline-block",
                width: 2,
                transition: "all 200ms ease-in-out",
              },
              "& > li.active": {
                bg: colors.modes["group"].primary,
                transition: "all 200ms ease-in-out 70ms",
              },
            }}
          >
            {dots()}
          </ul>
        </div>
      </div>

      <div
        className="fullwidth-slide--text-container"
        data-mode={mode}
        sx={{
          position: ab({ _: "relative", md: "absolute" }),
          bottom: ab({ _: "auto", md: "10px" }),
          left: ab({ _: 0, md: 4 }),
          top: ab({ _: "auto", md: "auto" }),
          p: 4,
          bg: modeKey
            ? colors.modes[modeKey].primary
            : colors.modes["group"].primary,
          maxWidth: ab({ _: "100%", md: "400px", lg: "550px" }),
          zIndex: [0, 3],
        }}
      >
        <div
          sx={{
            color: "#fff",
          }}
        >
          <h6
            sx={{
              m: 0,
              p: 0,
              fontSize: "14px",
              letterSpacing: 2,
              fontWeight: 1,
              textTransform: "uppercase",
            }}
          >
            {subHeading}
          </h6>
          <h1
            sx={{
              fontSize: "24px !important",
              my: 1,
              p: "0 !important",
            }}
          >
            {heading}
          </h1>
        </div>
      </div>
    </div>
  )
}

BannerNews.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  subHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  video: PropTypes.object,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  linkTarget: PropTypes.string,
  disableHyphens: PropTypes.bool,
}

BannerNews.defaultProps = {
  mode: "",
  disableHyphens: false,
}

export default BannerNews
