/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"
import { useEffect, useRef } from "react"

const SpecialHeader = ({ slogan, pageTitle }) => {
  const refDiv = useRef(null)

  useEffect(() => {
    let i = 0
    const intervalId = setInterval(() => {
      if (i < pageTitle.length) {
        if (refDiv !== null) {
          const getId = refDiv.current.id
          document.getElementById(getId).innerHTML += pageTitle.charAt(i)
          i++
        }
      }
    }, 100)
    return () => clearInterval(intervalId)
  }, [pageTitle])

  return (
    <div
      sx={{
        paddingLeft: ab({ _: 3, md: "300px" }),
        my: ab({ _: 3, md: "55px" }),
        fontSize: 1,
      }}
    >
      {slogan}{" "}
      <div
        id="typewriter"
        ref={refDiv}
        sx={{
          fontWeight: "bold",
          display: "inline-block",
          pr: 2,
        }}
      ></div>
    </div>
  )
}
SpecialHeader.propTypes = {
  slogan: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export default SpecialHeader
