import { useStaticQuery, graphql } from "gatsby"

const useAlgolia = () => {
  const { sitePlugin } = useStaticQuery(
    graphql`
      query AlgoliaQuery {
        sitePlugin(name: { eq: "gatsby-theme-gba" }) {
          pluginOptions
        }
      }
    `
  )

  return sitePlugin.pluginOptions
}

export { useAlgolia }
