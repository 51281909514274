/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const DepartmentSelect = ({
  departments,
  onChangeHandler = () => {},
  valueField = "email",
}) => {
  return (
    <div
      sx={{
        variant: "form.formField",
      }}
    >
      <select
        sx={{
          variant: "form.input",
        }}
        onChange={(e) => onChangeHandler(e.target.value)}
        name="department-selection"
      >
        {departments.map((item) => (
          <option value={item[valueField]}>{item.department}</option>
        ))}
      </select>
    </div>
  )
}

DepartmentSelect.propTypes = {
  departments: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func,
}

export default DepartmentSelect
