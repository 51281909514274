/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import { ab } from "../theme"

const CallToActionArea = ({
  heading,
  subHeading,
  buttonLink,
  buttonText,
  children,
  mode,
  download,
  triggerSearchDialog,
  linkTarget,
  toggleSearchDialog,
  currentLanguage,
  disableHyphens,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  let hyphensStyling = {}
  if (disableHyphens) {
    hyphensStyling = {
      hyphens: "none",
      wordWrap: "normal",
    }
  }
  const renderActionText = (triggerSearch) => {
    if (triggerSearch) {
      return (
        <button
          onClick={triggerSearchDialog ? () => toggleSearchDialog(true) : null}
          sx={{
            color: "#fff",
            fontSize: 3,
            py: 2,
            px: 4,
            alignSelf: "center",
            border: "solid 1px #fff !important",
            variant: "styles.ghostButton",
            "&:hover": {
              bg: "#fff",
            },
            "&:hover a": {
              color: modeKey
                ? colors.modes[modeKey].primary
                : colors.modes["group"].primary,
            },
            "& a": {
              color: "#fff",
            },
          }}
        >
          <span sx={{ color: "#fff" }}>{buttonText}</span>
        </button>
      )
    }

    return (
      <Link
        to={buttonLink}
        download={download}
        language={currentLanguage}
        sx={{
          color: "#fff",
          textDecoration: "none",
          borderColor: "#fff !important",
        }}
        target={linkTarget}
      >
        <button
          onClick={triggerSearchDialog ? () => toggleSearchDialog(true) : null}
          sx={{
            color: "#fff",
            fontSize: 3,
            py: 2,
            px: 4,
            alignSelf: "center",
            border: "solid 1px #fff !important",
            variant: "styles.ghostButton",
            "&:hover": {
              bg: "#fff",
              color: modeKey
                ? colors.modes[modeKey].primary
                : colors.modes["group"].primary,
            },
          }}
        >
          {buttonText}
        </button>
      </Link>
    )
  }

  return (
    <div className="cta-root" data-mode={mode}>
      <div
        sx={{
          variant: "innerSpacingContainer",
          bg: modeKey
            ? colors.modes[modeKey].primary
            : colors.modes["group"].primary,
          color: "background",
        }}
      >
        <div
          sx={{
            "& + div": {
              mt: 0,
              pt: 0,
            },
            display: ab({ md: "flex" }),
          }}
        >
          <div
            sx={{
              flex: 8,
              paddingRight: [0, 3],
            }}
          >
            <h6
              sx={{
                fontSize: "14px",
                letterSpacing: 2,
                my: 1,
                textTransform: "uppercase",
                fontWeight: 1,
              }}
            >
              {subHeading}
            </h6>
            <h2
              sx={{
                variant: "styles.h2",
                my: 2,
                ...hyphensStyling,
              }}
            >
              {heading}
            </h2>
            <div sx={{ "& p": { my: 1 } }}>{children}</div>
          </div>

          <div
            sx={{
              py: [3, 2, 0],
              display: buttonText ? ab({ _: "block", md: "flex" }) : "none",
              color: "background",
              flex: 4,
              justifyContent: ["left", "center"],
            }}
          >
            {renderActionText(triggerSearchDialog)}
          </div>
        </div>
      </div>
    </div>
  )
}

CallToActionArea.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  download: PropTypes.bool,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  triggerSearchDialog: PropTypes.bool,
  linkTarget: PropTypes.string,
  toggleSearchDialog: PropTypes.func,
  disableHyphens: PropTypes.bool,
}

CallToActionArea.defaultProps = {
  mode: "",
  download: false,
  triggerSearchDialog: false,
  disableHyphens: false,
}

export default CallToActionArea
