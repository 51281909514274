/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"

import DepartmentSelect from "./form/department-select"

const ContactViaMail = ({ label, mailListing }) => {
  const [mailTarget, setMailTarget] = useState()

  const selectDepartmentHandler = (department) => {
    setMailTarget(department)
  }

  useEffect(() => {
    if (mailListing.length) {
      setMailTarget(mailListing[0].email)
    }
  }, [])

  return (
    <div
      sx={{
        variant: "innerSpacingContainer",
        maxWidth: "1100px",
      }}
    >
      <DepartmentSelect
        departments={mailListing}
        onChangeHandler={selectDepartmentHandler}
      />
      {mailTarget && (
        <div
          sx={{
            mt: 2,
            pl: 1,
          }}
        >
          {label}: <a href={`mailto:${mailTarget}`}>{mailTarget}</a>
        </div>
      )}
    </div>
  )
}

ContactViaMail.propTypes = {
  label: PropTypes.string.isRequired,
  mailListing: PropTypes.array.isRequired,
}

export default ContactViaMail
