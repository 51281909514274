/** @jsx jsx */
import { jsx } from "theme-ui"
import { createRef, useState, useEffect } from "react"
import PropTypes from "prop-types"

const Input = ({
  label,
  name,
  emailLabel,
  type,
  value,
  placeholder,
  required,
  options,
  flex,
  visible,
  min,
  disabled,
  shopItem,
  minlength,
}) => {
  const [error, setError] = useState(false)
  const [input, setInput] = useState({})
  const [labelValue, setLabelValue] = useState("")
  const handleInputChange = (e) => {
    setError(false)
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }
  const inputEl = createRef()
  const showError = () => {
    setError(true)
    inputEl.current.focus()
    return
  }

  useEffect(() => {
    setLabelValue(emailLabel)
  }, [emailLabel])

  const onBlurInput = () => {
    if (shopItem && inputEl.current.value === "0") {
      inputEl.current.value = ""
    }
  }

  let labelElement = ""

  if (label) {
    labelElement = (
      <label
        sx={{
          display: label ? "block" : "none",
          py: label ? 1 : 0,
        }}
        htmlFor={name}
      >
        {label}
        {required && label ? " * " : ""}
      </label>
    )
  }
  const labelValueInput = (
    <input
      type="hidden"
      name={"label----" + name}
      value={labelValue ? labelValue : label ? label : placeholder}
    />
  )
  return (
    <div
      sx={{
        display: type === "hidden" ? "none" : "block",
        marginTop: 3,
        flex: flex,
      }}
    >
      {labelElement}
      {labelValueInput}
      <div
        className="formField"
        sx={{
          variant: "form.formField",
          border: error ? "solid #ff3454 2px" : "",
        }}
      >
        <input
          sx={{
            variant: "form.input",
          }}
          ref={inputEl}
          name={name}
          id={name}
          defaultValue={value}
          type={type}
          placeholder={
            placeholder && required ? placeholder + " * " : placeholder
          }
          required={required && visible}
          min={type === "number" ? min : ""}
          disabled={disabled}
          onInvalid={showError}
          onBlur={() => onBlurInput()}
          {...options}
          onChange={handleInputChange}
          minlength={minlength}
        />
      </div>
    </div>
  )
}

Input.defaultProps = {
  visible: true,
  disabled: false,
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  emailLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  flex: PropTypes.string,
  visible: PropTypes.bool,
  shopItem: PropTypes.bool,
}

export default Input
